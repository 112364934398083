<template>
  <div>
    <AccountStepper :current-step="3" />

    <div>
      <div class="flex items-center justify-center">
        <div>
          <div class="register__container-top mb-32">
            <p @click="backStep">
              <span>&lsaquo;</span>
              <span>{{ $t('register.config-payment.back') }}</span>
            </p>
          </div>
          <Form
            @submit="onSubmit"
            :validation-schema="validationSchema"
            class="flex flex-row mt-8 mx-auto gap-32"
          >
            <div>
              <div class="register__container payment-container">
                <p
                  class="text-lg font-medium"
                  v-html="$t('register.config-payment.description')"
                />

                <div>
                  <div class="register__form" v-if="planSelected">
                    <div class="register__form-grid-cols-2">
                      <div>
                        <p class="mb-8">
                          <label for="dueDate" class="text-md font-bold">
                            Plan *
                          </label>
                        </p>
                        <Dropdown
                          :items="planOptions"
                          :default-option="planSelected"
                          @onChange="onChangePlan"
                        />
                      </div>
                      <div>
                        <p class="mb-8">
                          <label class="text-md font-bold mb-8" for="licenses">
                            {{ $t('register.config-payment.form.licenses') }} *
                          </label>
                        </p>
                        <Field
                          name="licenses"
                          v-slot="{ errorMessage, field }"
                          v-model="licenses"
                        >
                          <input
                            class="input text-end"
                            id="licenses"
                            data-test-id="licensesNumberInput"
                            type="number"
                            :min="planSelected.settings.minLicenses"
                            autocomplete="off"
                            :class="{ error: errorMessage }"
                            v-bind="field"
                          />
                          <p
                            class="register__form-group-error"
                            v-if="errorMessage"
                          >
                            {{ errorMessage }}
                          </p>
                        </Field>
                        <p data-test-id="licenseCostLabel" class="text-sm mt-8">
                          {{ $t('register.config-payment.cost-per-license') }}:
                          ${{
                            planSelected.settings.price[currencySelected]
                          }}.00{{ $t('register.config-payment.vat') }}
                        </p>
                      </div>
                    </div>

                    <p
                      v-if="!showPromoCode"
                      class="text-link color-link text-md"
                      @click="showPromoCode = true"
                    >
                      {{ $t('register.config-payment.have-promotional-code') }}
                    </p>

                    <div v-if="showPromoCode">
                      <p class="mb-8">
                        <label
                          for="placeholder"
                          class="text-md font-bold"
                          data-test-id="couponActivationLink"
                          >{{ $t('register.config-payment.enter-promo-code') }}
                          *
                        </label>
                      </p>
                      <div class="flex gap-8">
                        <div class="w-full">
                          <Field
                            name="promoCode"
                            v-model="promoCode"
                            v-slot="{ errorMessage, field }"
                          >
                            <Input
                              id="placeholder"
                              data-test-id="couponCodeInput"
                              autocomplete="off"
                              :readonly="!!promoCodeApplied"
                              :has-error="!!errorPromoMessage"
                              :class="[{ error: errorMessage }, 'input w-full']"
                              v-bind="field"
                            />
                            <transition name="feed">
                              <p
                                v-if="messagePromo && !errorPromoMessage"
                                class="text-sm mt-8 text-hint"
                                data-test-id="messageDiscountLabel"
                              >
                                {{ messagePromo }}
                              </p>
                            </transition>
                            <transition name="fade">
                              <p
                                data-test-id="couponErrorLabel"
                                class="error-text mt-8"
                                v-if="errorMessage || errorPromoMessage"
                              >
                                {{ errorMessage || errorPromoMessage }}
                              </p>
                            </transition>
                          </Field>
                        </div>
                        <Button
                          v-if="!promoCodeApplied"
                          class="text-md"
                          type="button"
                          :disabled="!promoCode || isLoadingCheckPromo"
                          data-test-id="applyCouponButton"
                          @click="checkPromoCode"
                        >
                          {{ $t('register.config-payment.apply') }}
                        </Button>
                        <Button
                          v-else
                          class="text-md p-0 pt-4 btn-promo"
                          type="button"
                          :disabled="!promoCode"
                          @click="clearPromoCode"
                        >
                          <CloseIcon class="icon" />
                        </Button>
                      </div>
                    </div>

                    <div v-show="!freePromo" class="flex flex-col gap-y-24">
                      <div>
                        <p class="mb-8">
                          <label for="placeholder" class="text-md font-bold">
                            {{ $t('register.config-payment.form.placeholder') }}
                            *
                          </label>
                        </p>
                        <Field
                          name="placeholder"
                          v-slot="{ errorMessage, field }"
                        >
                          <input
                            id="placeholder"
                            data-test-id="nameOnCardText"
                            type="text"
                            autocomplete="off"
                            :class="[{ error: errorMessage }, 'input w-full']"
                            v-bind="field"
                          />
                          <transition name="fade">
                            <span
                              data-test-id="incorrectNameLabel"
                              class="register__form-group-error"
                              v-if="errorMessage"
                              >{{ errorMessage }}</span
                            >
                          </transition>
                        </Field>
                      </div>

                      <div>
                        <div class="flex justify-between">
                          <p class="mb-8">
                            <label for="numberCard" class="text-md font-bold">
                              {{ $t('register.config-payment.form.card') }} *
                            </label>
                          </p>
                          <div class="cards">
                            <CardMastercard />
                            <CardVisa />
                          </div>
                        </div>
                        <div
                          id="card-number-element"
                          class="input-custom"
                        ></div>
                      </div>

                      <div class="register__form-grid-cols-2">
                        <div>
                          <p class="mb-8">
                            <label for="dueDate" class="text-md font-bold">
                              {{ $t('register.config-payment.form.due-date') }}
                              *
                            </label>
                          </p>
                          <div
                            id="card-expiry-element"
                            class="input-custom"
                          ></div>
                        </div>
                        <div>
                          <p class="mb-8">
                            <label for="cvc" class="text-md font-bold">
                              {{ $t('register.config-payment.form.cvc') }} *
                            </label>
                          </p>
                          <div id="card-cvc-element" class="input-custom"></div>
                        </div>
                      </div>
                    </div>

                    <div
                      v-if="isBillingAllowed()"
                      class="register__form-checkbox"
                    >
                      <input
                        data-test-id="invoiceRequiredCheckbox"
                        id="invoice-required"
                        type="checkbox"
                        v-model="billing"
                      />
                      <label for="invoice-required" class="text-sm">{{
                        $t('register.config-payment.invoice-required')
                      }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="planSelected">
              <div class="payment-summary">
                <div class="grid grid-cols-2">
                  <div>
                    <p class="text-sm">Plan</p>
                    <p
                      class="font-bold text-md"
                      data-test-id="selectedPlanLabel"
                    >
                      {{ planSelected.name }}
                    </p>
                  </div>
                  <div>
                    <p class="text-sm">
                      {{ $t('register.config-payment.form.licenses') }}
                    </p>
                    <p
                      class="font-bold text-md"
                      data-test-id="selectedAmountLicensesLabel"
                    >
                      {{ licenses }}
                    </p>
                  </div>
                </div>
                <div v-if="promoCodeApplied" class="plan-selected mt-24">
                  <div>
                    <p class="text-sm">
                      {{ $t('register.config-payment.form.coupon') }}
                    </p>
                    <p class="font-bold" data-test-id="selectedCouponLabel">
                      {{ promoCodeApplied?.code }}
                    </p>
                    <p v-if="promoCodeApplied" class="text-sm text-success">
                      {{ disclosureMessage }}
                    </p>
                  </div>
                </div>
                <div>
                  <div class="price-summary" data-test-id="planAmountLabel">
                    <p
                      data-test-id="planTotalAmountLabel"
                      class="font-semibold text-h2"
                    >
                      {{
                        formatCurrency(
                          promoCodeApplied ? discountAmount : totalWithTaxes
                        )
                      }}
                    </p>
                    <p v-if="discountAmount !== 0" class="text-lg font-medium">
                      /{{ $t('register.config-payment.month') }}*
                    </p>
                  </div>
                  <div
                    v-if="!promoCodeApplied || discountAmount !== 0"
                    class="flex justify-end"
                  >
                    <div class="text-sm text-end">
                      <p data-test-id="planSubtotalLabel">
                        Subtotal:
                        {{ formatCurrency(total) }}
                      </p>
                      <p data-test-id="taxAmountLabel">
                        {{
                          $t('register.config-payment.vat-included', {
                            total: formatCurrency(taxes),
                            tax: TAX_RATE * 100,
                          })
                        }}
                      </p>
                      <hr />
                      <p data-test-id="totalAmountLabel">
                        Total:
                        {{ formatCurrency(totalWithTaxes) }}
                      </p>
                    </div>
                  </div>
                  <p
                    v-if="promoCodeApplied?.amount"
                    class="text-sm text-end text-success"
                    data-test-id="paymentDiscountLabel"
                  >
                    {{
                      $t('register.config-payment.discount', {
                        amount: formatCurrency(-promoCodeApplied.amount),
                      })
                    }}
                  </p>
                  <p
                    v-if="promoCodeApplied?.percent"
                    class="text-sm text-end text-success"
                    data-test-id="paymentDiscountLabel"
                  >
                    {{
                      $t('register.config-payment.discount-percent', {
                        percent: promoCodeApplied.percent,
                        amount: formatCurrency(
                          totalWithTaxes * (promoCodeApplied.percent / 100)
                        ),
                      })
                    }}
                  </p>
                </div>
                <div class="register__form-checkbox mt-24">
                  <input
                    data-test-id="renewalConfirmCheckbox"
                    id="renewal-confirm"
                    type="checkbox"
                    v-model="renewalConfirm"
                  />
                  <label
                    v-if="promoCodeApplied?.free"
                    for="renewal-confirm"
                    class="text-xs"
                    data-test-id="checkboxDisclosureMessage"
                  >
                    {{
                      $t('register.config-payment.promo-disclosure-free', {
                        days: promoCodeApplied.free,
                        date: $moment()
                          .add(promoCodeApplied.free, 'days')
                          .format('LL'),
                        total: formatCurrency(totalWithTaxes),
                      })
                    }}
                  </label>
                  <label
                    v-else-if="promoCodeApplied?.percent"
                    for="renewal-confirm"
                    class="text-xs"
                    data-test-id="checkboxDisclosureMessage"
                  >
                    {{
                      $t('register.config-payment.promo-disclosure-percent', {
                        date: formatLongDate(nextMonth),
                        percent: promoCodeApplied.percent,
                        total: formatCurrency(totalWithTaxes),
                      })
                    }}
                  </label>
                  <label
                    v-else-if="promoCodeApplied?.amount"
                    for="renewal-confirm"
                    class="text-xs"
                    data-test-id="checkboxDisclosureMessage"
                  >
                    {{
                      $t('register.config-payment.promo-disclosure-amount', {
                        amount: formatCurrency(promoCodeApplied.amount),
                        date: formatLongDate(nextMonth),
                        total: formatCurrency(totalWithTaxes),
                      })
                    }}
                  </label>
                  <label
                    v-else
                    for="renewal-confirm"
                    class="text-xs"
                    data-test-id="checkboxDisclosureMessage"
                  >
                    *{{
                      $t('register.config-payment.renovation-date', {
                        day: nextMonth.getDate(),
                      })
                    }}
                  </label>
                </div>
              </div>

              <Button
                type="submit"
                class="mt-16 w-full"
                :disabled="isLoading || !renewalConfirm"
                data-test-id="confirmPayButton"
              >
                {{
                  promoCodeApplied && discountAmount === 0
                    ? $t('register.config-payment.form.next')
                    : $t('register.config-payment.form.pay')
                }}
              </Button>

              <p
                v-if="errorMsg.length"
                data-test-id="paymentErrorLabel"
                class="register__form-default-error text-center mt-8 text-md"
              >
                {{ errorMsg }}
              </p>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
  <OverlayLoader @finish="redirect" />
</template>

<script setup>
import { onMounted, ref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { Field, Form } from 'vee-validate';
import { datadogRum } from '@datadog/browser-rum';
import { useUserStore } from '@/stores/user';
import AccountStepper from '@/components/account/AccountStepper.vue';
import router from '@/router';
import { loadStripe } from '@stripe/stripe-js';
import CardVisa from '@/assets/images/card-visa.svg';
import CardMastercard from '@/assets/images/card-mastercard.svg';
import { getTiers } from '@/services/tier';
import { TAX_RATE } from '@/consts/roles';
import Dropdown from '@/components/Dropdown.vue';
import Button from '@/components/Button.vue';
import { getPromoCode } from '@/services/payment';
import CloseIcon from '@/assets/images/close.svg';
import Input from '@/components/Input.vue';
import OverlayLoader from '@/components/OverlayLoader.vue';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);
const store = useUserStore();
const { t } = useI18n();
const isLoading = ref(false);
const isLoadingCheckPromo = ref(false);
const errorMsg = ref('');
const stripe = ref(null);
const licenses = ref('2');
const billing = ref(false);
const renewalConfirm = ref(false);
const cardNumberElement = ref(null);
const tiers = ref([]);
const planSelected = ref(null);
const currencySelected = ref('mxn');
const showPromoCode = ref(false);
const promoCode = ref('');
const errorPromoMessage = ref(null);
const promoCodeApplied = ref(null);

const validationSchema = computed(() => {
  return {
    licenses: 'required|integer',
    placeholder: !freePromo.value ? 'required|min:2' : '',
  };
});

const isMex = computed(() => store.$state.countrySelected === 'MEX');

const taxRate = computed(() => {
  return TAX_RATE;
});

const total = computed(() => {
  if (!planSelected.value) {
    return 0;
  }

  return (
    planSelected.value.settings.price[currencySelected.value] * licenses.value
  );
});

const taxes = computed(() => {
  return total.value * taxRate.value;
});

const totalWithTaxes = computed(() => {
  return total.value + taxes.value;
});

const nextMonth = computed(() => {
  const date = new Date();
  date.setMonth(date.getMonth() + 1);
  return date;
});

const planOptions = computed(() => {
  return tiers.value.map((tier) => ({
    name: tier.name,
    value: tier,
  }));
});

const freePromo = computed(() => {
  return (
    (promoCodeApplied.value &&
      promoCodeApplied.value?.type === 'percent' &&
      promoCodeApplied.value?.percent === 100) ||
    promoCodeApplied.value?.type === 'free' ||
    promoCodeApplied.value?.amount >= totalWithTaxes.value
  );
});

const messagePromo = computed(() => {
  if (promoCodeApplied.value) {
    if (promoCodeApplied.value.type === 'amount') {
      return t('register.config-payment.promo-code-applied-amount', {
        amount: formatCurrency(-promoCodeApplied.value.amount),
      });
    }

    if (promoCodeApplied.value.free) {
      return t('register.config-payment.promo-code-applied-free', {
        days: promoCodeApplied.value.free,
      });
    }

    if (promoCodeApplied.value.percent) {
      return t('register.config-payment.promo-code-applied-percent', {
        percent: -promoCodeApplied.value.percent,
      });
    }
  }

  return false;
});

const disclosureMessage = computed(() => {
  if (promoCodeApplied.value.free) {
    return t('register.config-payment.promo-label-free', {
      days: promoCodeApplied.value.free,
    });
  }

  if (promoCodeApplied.value.amount) {
    return t('register.config-payment.promo-code-applied-amount', {
      amount: formatCurrency(-promoCodeApplied.value.amount),
    });
  }

  if (promoCodeApplied.value.percent) {
    return t('register.config-payment.promo-code-applied-percent', {
      percent: -promoCodeApplied.value.percent,
    });
  }

  return '';
});

const discountAmount = computed(() => {
  if (promoCodeApplied.value?.type === 'amount') {
    if (promoCodeApplied.value.amount > totalWithTaxes.value) {
      return 0;
    }

    return totalWithTaxes.value - promoCodeApplied.value.amount;
  }

  if (promoCodeApplied.value?.type === 'percent') {
    if (promoCodeApplied.value.percent === 100) {
      return 0;
    }

    return (
      totalWithTaxes.value -
      totalWithTaxes.value * (promoCodeApplied.value.percent / 100)
    );
  }

  if (promoCodeApplied.value?.free) {
    return 0;
  }

  return totalWithTaxes.value;
});

watch(
  () => promoCode.value,
  () => {
    if (!promoCode.value) {
      errorPromoMessage.value = null;
    }
  }
);

const loadTiers = async () => {
  try {
    const tierList = await getTiers();

    const { data } = tierList;
    tiers.value = data;
    tiers.value = tierList.data;

    const [firstTier] = tierList.data;
    planSelected.value = firstTier;

    if (store.$state.planSelected) {
      const plan = tierList.data.find(
        (tier) => tier.name === store.$state.planSelected
      );

      if (plan) {
        planSelected.value = plan;
      }
    }

    licenses.value = planSelected.value.settings.minLicenses;
  } catch (error) {
    datadogRum.addError(error);
    console.error(error);
  }
};

const loadStripeForm = async () => {
  stripe.value = await stripePromise;
  const elements = stripe.value.elements();

  cardNumberElement.value = elements.create('cardNumber', {
    classes: {
      base: 'input-payment',
    },
  });

  const cardExpiry = elements.create('cardExpiry', {
    classes: {
      base: 'input-payment',
    },
  });

  const cardCvc = elements.create('cardCvc', {
    classes: {
      base: 'input-payment',
    },
  });

  cardExpiry.mount('#card-expiry-element');
  cardNumberElement.value.mount('#card-number-element');
  cardCvc.mount('#card-cvc-element');
};

const onChangePlan = (plan) => {
  planSelected.value = plan.value;

  if (plan.value.settings.minLicenses > licenses.value) {
    licenses.value = plan.value.settings.minLicenses;
  }
};

const createToken = async (cardholder) => {
  try {
    const response = await stripe.value.createToken(cardNumberElement.value, {
      name: cardholder,
    });

    if (response.error) {
      errorMsg.value = response.error.message;
      return false;
    }

    return response.token;
  } catch (error) {
    errorMsg.value = t('default-error');
    datadogRum.addError(error);
    return false;
  }
};

const backStep = () => {
  router.push({ name: 'ConfigSpace' });
};

const clearPromoCode = () => {
  errorPromoMessage.value = null;
  promoCodeApplied.value = null;
  promoCode.value = '';
};

const checkPromoCode = async () => {
  try {
    errorPromoMessage.value = null;
    isLoadingCheckPromo.value = true;

    if (promoCodeApplied.value) {
      clearPromoCode();
      return;
    }

    const code = promoCode.value.toUpperCase().trim();

    const { data } = await getPromoCode({
      code,
      total: totalWithTaxes.value,
      currency: 'mxn',
      tier: planSelected.value._id,
    });

    if (data.success) {
      if (data.coupon?.amount) {
        promoCodeApplied.value = {
          code,
          type: 'amount',
          amount: data.coupon.amount / 100,
        };
      } else if (data.coupon?.percent) {
        promoCodeApplied.value = {
          code,
          type: 'percent',
          percent: data.coupon.percent,
        };
      } else if (data.coupon?.free) {
        promoCodeApplied.value = {
          code,
          type: 'free',
          free: data.coupon.free,
        };
      }
    }
  } catch (error) {
    if (error.response?.data?.success === false) {
      if (error.response.data.type === 'couponNotValid') {
        errorPromoMessage.value = t(
          'register.config-payment.promo-code-expired'
        );
        return;
      }

      if (error.response.data.message === 'Invalid coupon') {
        errorPromoMessage.value = t(
          'register.config-payment.promo-code-invalid'
        );
        return;
      }

      errorPromoMessage.value = error.response.data.message;
      return;
    }

    datadogRum.addError(error);
    console.error(error);
    errorPromoMessage.value = t('register.config-payment.promo-code-invalid');
  } finally {
    isLoadingCheckPromo.value = false;
  }
};

const redirect = () => {
  if (errorMsg.value !== '') {
    return;
  }

  if (billing.value) {
    store.setStepCreatingAccount(5);
    router.push({ name: 'ConfigBilling' });
  } else {
    store.setStepCreatingAccount(6);
    router.push({ name: 'ConfigPaid' });
  }
};

const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return null;
};

const onSubmit = async (values) => {
  errorMsg.value = '';
  isLoading.value = true;

  if (values.licenses < planSelected.value.settings.minLicenses) {
    errorMsg.value = t('register.config-payment.licenses-required-in-tier', {
      minLicenses: planSelected.value.settings.minLicenses,
      planName: planSelected.value.name,
    });

    isLoading.value = false;
    return;
  }

  if (promoCode.value && !promoCodeApplied.value) {
    errorPromoMessage.value = t(
      'register.config-payment.promo-code-not-applied'
    );
    isLoading.value = false;
    return;
  }

  let token = null;

  if (!promoCodeApplied.value || discountAmount.value !== 0) {
    token = await createToken(values.placeholder);

    if (!token) {
      isLoading.value = false;

      if (!errorMsg.value) {
        errorMsg.value = t('default-error');
      }
      return;
    }
  }

  store.activateLoading(true);
  store.changePlan({
    ...planSelected.value,
    licenses: values.licenses,
    currencyCode: currencySelected.value.toUpperCase(),
  });
  try {
    await store.configBilling({
      currency_code: currencySelected.value.toUpperCase(),
    });
  } catch (error) {
    datadogRum.addError(error);
    errorHandler(error);
    console.error(error);
  }

  try {
    const payload = {
      token: token?.id,
      coupon: promoCodeApplied.value?.code?.toUpperCase().trim(),
      plan_id: planSelected.value._id,
      licenses: values.licenses.toString(),
      company_id: store.$state.company?._id || store.$state.companyId,
      currency: currencySelected.value,
    };

    if (getCookie('testClock')) {
      payload.test_clock = true;
    }

    await store.createPayment(payload);

    isLoading.value = false;
  } catch (error) {
    datadogRum.addError(error);
    errorHandler(error);
  } finally {
    isLoading.value = false;
    store.activateLoading(false);
  }
};

const formatCurrency = (value) => {
  return new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
  }).format(value);
};

const formatLongDate = (date) => {
  return date.toLocaleDateString('es-ES', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });
};

const errorHandler = (error) => {
  if (error.response?.data?.errors && error.response?.data?.errors.length) {
    const { instancePath, message } = error.response.data.errors[0];
    errorMsg.value = `${instancePath.slice(1, -1)} ${message}`;
    return;
  }
  if (error.response?.data?.message) {
    errorMsg.value = error.response.data.message;
    return;
  }
  errorMsg.value = t('default-error');
};

const isBillingAllowed = () => isMex.value;

const init = () =>
  Promise.allSettled([loadTiers(), store.fetchUser(), loadStripeForm()]);

onMounted(() => {
  init();

  if (store.$state.stepCreatingAccount !== 4) {
    store.setStepAccountURL();
  }
});
</script>

<style lang="scss" scoped>
@import '~styles/views/create-account';
</style>
